import React, {useEffect} from "react";
import "../../styles/Bay.css";
import {BayDbModel, BayStatusType} from "../../models/bays";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";

import {AppBar, createStyles, makeStyles, Theme, Typography} from "@material-ui/core";
import {Facility} from "../../models/facility";
import {Bay} from "./Bay";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    loading: {
      display: "flex",
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      textAlign: "left",
      font: "normal normal 300 19px/22px Montserrat",
      letterSpacing: "0px",
      color: "#7D7D7D",
      textTransform: "uppercase",
      opacity: 1,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    floorUtilization: {
      flexGrow: 1,
      textAlign: "end",
      font: "normal normal 300 19px/22px Montserrat",
      letterSpacing: "0px",
      color: "#7D7D7D",
      textTransform: "uppercase",
      opacity: 1,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    floor: {
      background: "black",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

export const BaysStatusView: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedFacility = useSelector<RootState, Facility>(
    (selector) => selector.facility.selectedFacility
  );

  const bays = useSelector<RootState, BayDbModel[]>(
      (selector) => selector.bays.bays,
      shallowEqual // Prevents re-renders if the array contents haven’t changed
  );

  const [firstFloorUtilization, setFirstFloorUtilization] = React.useState(
    0
  );
  const [seconodFloorUtilization, setSecondFloorUtilization] = React.useState(
    0
  );
  const [thirdFloorUtilization, setThirdFloorUtilization] = React.useState(
    0
  );
  const [fourthFloorUtilization, setFourthFloorUtilization] = React.useState(
      0
  );

  const getFloorUtilization = (floor: number) => {
    let baysInUse = bays.filter(
        (x) => x.bayFloor === floor && x.bayStatusType !== BayStatusType.Available
    ).length;
    let baysTotal = bays.filter((x) => x.bayFloor === floor).length;
    if (baysTotal > 0) {
      let per = baysInUse / baysTotal;
      if (per > 0) {
        return (per * 100).toPrecision(3);
      }
      return 0;
    }
    return 100;
  };

  // useEffect(() => {
  //   // const timeout = setTimeout(() => {
  //   setTimeout(() => {
  //     dispatch(getBays(selectedFacility.uuid));
  //   }, 10000);
  //   // return clearTimeout(timeout);
  // });

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setFirstFloorUtilization(getFloorUtilization(1) as number);
      setSecondFloorUtilization(getFloorUtilization(2) as number);
      setThirdFloorUtilization(getFloorUtilization(3) as number);
      setFourthFloorUtilization(getFloorUtilization(4) as number);
    }
    // return () => mounted = false;
  }, [bays]);

  return (
    <React.Fragment>
      {bays.filter((x) => x.bayFloor === 1).length > 0 && (
        <React.Fragment>
          <div style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <Typography variant="h6" color="primary" className={classes.title}>
              Floor 1
            </Typography>
            <Typography variant="h6" color="primary" className={classes.floorUtilization}>
              { Math.round(firstFloorUtilization) }%
            </Typography>
          </div>
          <AppBar
            position="static"
            style={{
              boxShadow: "none",
              height: "10px",
              background: `linear-gradient(to right, #F20093 0%, #730049 ${firstFloorUtilization}%, #FFFFFF ${firstFloorUtilization}%)`,
              borderRadius: "25px",
            }}
          >
          </AppBar>
          <div className="bays">
            {bays
              .filter((x) => x.bayFloor === 1)
              .map((bay) => {
                return <Bay key={bay.uuid} bay={bay} />;
              })}
          </div>
        </React.Fragment>
      )}
      {bays.filter((x) => x.bayFloor === 2).length > 0 && (
        <React.Fragment>
          <div style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <Typography variant="h6" color="primary" className={classes.title}>
              Floor 2
            </Typography>
            <Typography variant="h6" color="primary" className={classes.floorUtilization}>
              { Math.round(seconodFloorUtilization) }%
            </Typography>
          </div>
          <AppBar
            position="static"
            style={{
              boxShadow: "none",
              background: `linear-gradient(to right, #F20093 0%, #730049 ${seconodFloorUtilization}%, #FFFFFF ${seconodFloorUtilization}%)`,
              borderRadius: "25px",
              height: "10px",
            }}
          >
          </AppBar>
          <div className="bays">
            {bays
              .filter((x) => x.bayFloor === 2)
              .map((bay) => {
                return <Bay key={bay.uuid} bay={bay} />;
              })}
          </div>
        </React.Fragment>
      )}
      {bays.filter((x) => x.bayFloor === 3).length > 0 && (
        <React.Fragment>
          <div style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <Typography variant="h6" color="primary" className={classes.title}>
              Floor 3
            </Typography>
            <Typography variant="h6" color="primary" className={classes.floorUtilization}>
              { Math.round(thirdFloorUtilization) }%
            </Typography>
          </div>

          <AppBar
            position="static"
            style={{
              boxShadow: "none",
              background: `linear-gradient(to right, #F20093 0%, #730049 ${thirdFloorUtilization}%, #FFFFFF ${thirdFloorUtilization}%)`,
              borderRadius: "25px",
              height: "10px"
            }}
          >
          </AppBar>
          <div className="bays">
            {bays
              .filter((x) => x.bayFloor === 3)
              .map((bay) => {
                return <Bay key={bay.uuid} bay={bay} />;
              })}
          </div>
        </React.Fragment>
      )}
      {bays.filter((x) => x.bayFloor === 4).length > 0 && (
          <React.Fragment>
            <div style={{
              display: 'flex',
              alignItems: 'center'
            }}>
              <Typography variant="h6" color="primary" className={classes.title}>
                Floor 4
              </Typography>
              <Typography variant="h6" color="primary" className={classes.floorUtilization}>
                { Math.round(fourthFloorUtilization) }%
              </Typography>
            </div>

            <AppBar
                position="static"
                style={{
                  boxShadow: "none",
                  background: `linear-gradient(to right, #F20093 0%, #730049 ${fourthFloorUtilization}%, #FFFFFF ${fourthFloorUtilization}%)`,
                  borderRadius: "25px",
                  height: "10px"
                }}
            >
            </AppBar>
            <div className="bays">
              {bays
                  .filter((x) => x.bayFloor === 4)
                  .map((bay) => {
                    return <Bay key={bay.uuid} bay={bay} />;
                  })}
            </div>
          </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default BaysStatusView;
