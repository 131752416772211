import React, { useState, useEffect } from "react";
import "../../styles/Bay.css";
import { BaySettings } from "./BaySettings";
import { BayStatusType, BayDbModel } from "../../models/bays";
import ButtonBase from "@material-ui/core/ButtonBase";

interface BayProps {
  bay: BayDbModel;
}

export const BayLayoutItem: React.FC<BayProps> = ({ bay }) => {
  const [showSettings, setShowSettings] = useState<boolean>(false);

  const handleClose = () => setShowSettings(false);
  const handleShow = () => setShowSettings(true);

  const getBayDisplayName = (bay: BayDbModel) => {
    if (bay.bayName) {
      // Remove the word Bay from the bay name
      if (bay.bayName.toLowerCase().includes("bay")) {
          return bay.bayName.toLowerCase().replace("bay", "");
      }
      return bay.bayName;
    }
    return bay.bayNumber;
  }

  const getBayColorBayStateId = (bayStatusType: BayStatusType) => {
    switch (bayStatusType) {
      case BayStatusType.Available:
        return "#97DE3D";
      case BayStatusType.InUse:
        return "#FB963B";
      case BayStatusType.NewAssignment:
      case BayStatusType.Bussing:
      case BayStatusType.Waiter:
        return "#8C48AB"
      case BayStatusType.Reserved:
        return "#4D2178";
      case BayStatusType.Service:
      case BayStatusType.Technical:
        return "#EF4327";
      case BayStatusType.NonAssignable:
        return "#C1C1C1";
      case BayStatusType.InUseAndroid:
      case BayStatusType.InUseIos:
        return "#FD009B";
      case BayStatusType.InUseSPInBay:
        return "#1B4258";
      case BayStatusType.InUseMPInBay:
        return "#56EBFC";
    }
    return "#C1C1C1";
  };

  return (
    <React.Fragment>
      {
        <ButtonBase
          className="bayLayoutItem"
          style={{
            background: `${getBayColorBayStateId(bay.bayStatusType)}`,
            borderRadius: "5px",
          }}
          onClick={handleShow}
        >
          <label className="bayLayoutItemName">{getBayDisplayName(bay)}</label>
        </ButtonBase>
      }
      <BaySettings bay={bay} show={showSettings} handleClose={handleClose} />
    </React.Fragment>
  );
};

export default BayLayoutItem;
