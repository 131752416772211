import React, {useState, useEffect, useRef} from "react";
import "../../styles/Bay.css";
import {BayStatusType, BayPIN, BayDbModel, getBayMinutesLeft} from "../../models/bays";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import ButtonBase from "@material-ui/core/ButtonBase";
import { Facility } from "../../models/facility";
import {BaySettings} from "./BaySettings";

interface BayProps {
  bay: BayDbModel;
}

export const Bay: React.FC<BayProps> = ({ bay }) => {
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const dispatch = useDispatch();

  const bayStatusType = bay.bayStatusType;

  const selectedFacility = useSelector<RootState, Facility>(
      (selector) => selector.facility.selectedFacility
  );


  const calculateTimeLeft = () => {
    // Only calculate the time when in_use
    if (bayStatusType === BayStatusType.Available) {
      return 0;
    }

    if (bay.pin !== undefined && bay.pin !== null) {
      if (bay.startedAt === null) {
        return 0;
      }
      if (selectedFacility !== null && selectedFacility.behavior !== null) {
        let minutesLeft = getBayMinutesLeft(selectedFacility.behavior, selectedFacility.leadTimeMinutes, bay);

        if (minutesLeft < 0) {
          return 0
        }

        const percentLeft = 100 - (minutesLeft / bay.minutes) * 100;
        // percentLeft = percentLeft < 2 ? 2 : percentLeft;
        // if (bay.name === "1001") console.log(`${percentLeft}%`);
        return +percentLeft.toPrecision(2);

      } else {
        var start = new Date(bay.startedAt);
        var now = new Date(Date.now());
        var end = new Date(start.getTime() + 1000 * 60 * bay.minutes);

        if (end < now) return 98;
        var timeLeft2 = moment.utc(
            moment(end, "DD/MM/YYYY HH:mm:ss").diff(
                moment(now, "DD/MM/YYYY HH:mm:ss")
            )
        );
        var minutesLeft = moment
            .duration(timeLeft2.format("HH:mm:ss"))
            .asMinutes();
        const percentLeft = 100 - (minutesLeft / bay.minutes) * 100;
        // percentLeft = percentLeft < 2 ? 2 : percentLeft;
        // if (bay.name === "1001") console.log(`${percentLeft}%`);
        return +percentLeft.toPrecision(2);

      }
    }
    return 0;
  }

  const calculateTimeLeftOld = () => {
    // Only calculate the time when in_use
    if (bay.bayStatusType === BayStatusType.Available) {
      return 0;
    }

    if (bay.pin !== undefined && bay.pin !== null && bay.pin !== "") {
      if (bay.startedAt === null) {
        return 0;
      }
      var start = new Date(bay.startedAt);
      var now = new Date(Date.now());
      var end = new Date(start.getTime() + 1000 * 60 * bay.minutes);

      if (end < now) return 98;
      var timeLeft2 = moment.utc(
        moment(end, "DD/MM/YYYY HH:mm:ss").diff(
          moment(now, "DD/MM/YYYY HH:mm:ss")
        )
      );
      var minutesLeft = moment
        .duration(timeLeft2.format("HH:mm:ss"))
        .asMinutes();
      var percentLeft = 100 - (minutesLeft / bay.minutes) * 100;
      // percentLeft = percentLeft < 2 ? 2 : percentLeft;
      // if (bay.name === "1001") console.log(`${percentLeft}%`);
      return +percentLeft.toPrecision(2);
    }
    return 0;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const handleClose = () => setShowSettings(false);
  const handleShow = () => {
    //force an update before showing settings screen
    //dispatch(getBay(selectedFacility.uuid, bay.uuid));
    setShowSettings(true);
  };

  const getBayColorsBayStateId = (bayStatusType: BayStatusType) => {
    switch (bayStatusType) {
      case BayStatusType.Available:
        return ["#97DE3D", "#2EAB3A"];
      case BayStatusType.InUse:
        return ["#FB963B", "#F77D0F"];
      case BayStatusType.NewAssignment:
      case BayStatusType.Bussing:
      case BayStatusType.Waiter:
        return ["#8C48AB","#8C48AB"]
      case BayStatusType.Reserved:
        return ["#4D2178", "#4D2178"];
      case BayStatusType.Service:
      case BayStatusType.Technical:
        return ["#EF4327","#EF4327"];
      case BayStatusType.NonAssignable:
        return ["#C1C1C1", "#C1C1C1"];
      case BayStatusType.InUseAndroid:
      case BayStatusType.InUseIos:
        return ["#FD009B", "#350028"];
      case BayStatusType.InUseSPInBay:
        return ["#3A5A6C", "#041A27"];
      case BayStatusType.InUseMPInBay:
        return ["#56EBFC", "#008B9B"];
    }
    return ["#C1C1C1", "#C1C1C1"];
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, [bayStatusType]);

  // useEffect(() => {
  //   if (selectedFacility !== null) {
  //     setTimeLeft(calculateTimeLeft(bayStatusType));
  //   }
  // }, [dispatch, bay.uuid]);

  const getBayDisplayName = (bay: BayDbModel) => {
    if (bay.bayName) {
      // Remove the word Bay from the bay name
      if (bay.bayName.toLowerCase().includes("bay")) {
        return bay.bayName.toLowerCase().replace("bay", "");
      }
      return bay.bayName;
    }
    return bay.bayNumber;
  }

  const colors: string[] = getBayColorsBayStateId(bayStatusType);
  const color1 = colors[0];
  const color2 = colors[1];

  return (
    <React.Fragment>
      {
        <ButtonBase
          className={`bay ${
            timeLeft >= 98 && bayStatusType !== BayStatusType.Available ? "bayOutOfTime" : ""
          }`}
          style={{
            background: timeLeft > 0
                ? `linear-gradient(to bottom, ${color1} 0%, ${color1} ${timeLeft}%, ${color2} ${timeLeft}%, ${color2} 100%)`
                : `linear-gradient(to bottom, ${color1}, ${color2})`,
            borderRadius: "10px",
            overflow: "hidden",
            transition: "background 0.5s ease-in-out",
          }}
          onClick={handleShow}
          onMouseDown={(e) => (e.currentTarget.style.transform = "scale(0.95)")}
          onMouseUp={(e) => (e.currentTarget.style.transform = "scale(1)")}
          onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
        >

          <label className="bayName">{getBayDisplayName(bay)}</label>

          <div
              style={{
                position: "absolute",
                left: 0,
                width: "100%",
                height: "2px", // Line thickness
                backgroundColor: "#FD009B",
                bottom: `${Math.max(100 - timeLeft, 0)}%`, // Moves the line down as time decreases
                transition: "bottom 0.2s ease-in-out", // Smooth transition
              }}
          />


          {/*{timeLeft > 0 && (*/}
          {/*    <div style={{ fontSize: "8px", marginTop: "1px", opacity: 0.9 }}>*/}
          {/*      {Math.ceil(timeLeft)} min*/}
          {/*    </div>*/}
          {/*)}*/}

        </ButtonBase>
      }
      <BaySettings bay={bay} show={showSettings} handleClose={handleClose} />
    </React.Fragment>
  );
};

export default Bay;
