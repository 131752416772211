import {
  PINLOADING,
  PINLOADED,
  PINCREATING,
  PINCREATED,
  PINUPDATING,
  PINUPDATED,
  PINCOMPLETED,
  PINLOADINGDONE,
  PINERROR,
  PinsState, PINERRORHANDLED, PIN_UPCOMING, PIN_UPCOMING_HANDLED,
} from '../actions/pins.actions.types';

const initialState: PinsState = { isLoading: false, bayPins: [] };

export default (state: PinsState = initialState, action: any) => {
  switch (action.type) {
    case PINLOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PINLOADINGDONE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case PINLOADED: {
      return {
        ...state,
        bayPins: [
          ...state.bayPins.filter((x) => x.id !== action.payload.bayId),
          action.payload,
        ],
        isLoading: false,
      };
    }
    case PINCREATING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PINCREATED: {
      return {
        ...state,
        bayPins: [
          ...state.bayPins.filter((x) => x.bayId !== action.payload.bayId),
          action.payload,
        ],
        isLoading: false,
      };
    }
    case PINUPDATING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PINUPDATED: {
      return {
        ...state,
        bayPins: [
          ...state.bayPins.filter((x) => x.bayId !== action.payload.bayId),
          action.payload,
        ],
        isLoading: false,
      };
    }
    case PINCOMPLETED: {
      return {
        ...state,
        bayPins: [
          ...state.bayPins.filter((x) => x.bayId !== action.payload.bayId),
        ],
        isLoading: false,
      };
    }
    case PINERROR: {
      return {
        ...state,
        errorObject: {
          message: action.payload
        },
        isLoading: false,
      };
    }
    case PINERRORHANDLED: {
      return {
        ...state,
        errorObject: undefined,
        isLoading: false
      };
    }
    case PIN_UPCOMING: {
      return {
        ...state,
        isLoading: false,
        upcoming: action.payload,
      };
    }
    case PIN_UPCOMING_HANDLED: {
      return {
        ...state,
        upcoming: undefined,
        isLoading: false
      };
    }
    default:
      return state;
  }
};
