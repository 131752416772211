import { UserModel } from "../../models/users";
import React, { useState, useEffect } from "react";
import {
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  makeStyles,
  Theme,
  createStyles,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Snackbar,
  CircularProgress,
  MuiThemeProvider,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { BayPIN, BayDbModel, BayStatusType } from "../../models/bays";
import moment from "moment";
import axios from "axios";
import { Alert, AlertTitle } from "@material-ui/lab";
import { changeBayState } from "../../redux/actions/bays.actions";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import {
  createBayPin,
  completeBayPin,
} from "../../redux/actions/pins.actions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import UserDetails from "./UserDetails";
import { Facility } from "../../models/facility";

interface UserListItemProps {
  user: UserModel;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    root: {
      background: "white",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    notime: {
      color: "red",
    },
    time: {
      color: "primary",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

export const UserListItem: React.FC<UserListItemProps> = ({ user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const selectedFacility = useSelector<RootState, Facility>(
    (selector) => selector.facility.selectedFacility
  );
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [role, setRole] = useState<string>(user.role);
  const userRole = useSelector<RootState, string>(
    (selector) => selector.facility.role
  );

  const handleRoleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    console.log(user);
    if (
      (event.target.value == "ADMIN" && userRole == "ADMIN") ||
      (event.target.value == "MANAGER" &&
        (userRole == "MANAGER" || userRole == "ADMIN")) ||
      event.target.value == "USER"
    ) {
      axios.put(
        `${process.env.REACT_APP_BASE_URL}/Users/${selectedFacility.rangeId}/${
          user.id
        }/${event.target.value as string}`
      );
    }
    setRole(event.target.value as string);
  };

  const handleCloseDetails = () => setShowDetails(false);
  const handleShowDetails = () => {
    if (userRole == "MANAGER" || userRole == "ADMIN") setShowDetails(true);
  };

  const authorizeUser = () => {
    console.log(user);
    axios.post(
      `${process.env.REACT_APP_BASE_URL}/Users/${selectedFacility.rangeId}/${user.id}/USER`
    );
    setRole("USER");
  };

  const loading = useSelector<RootState, boolean>(
    (selector) => selector.pins.isLoading
  );

  return (
    <React.Fragment>
      {
        <TableRow key={user.id}>
          <TableCell align="right" onClick={handleShowDetails}>
            {user.firstName}
          </TableCell>
          <TableCell align="right" onClick={handleShowDetails}>
            {user.lastName}
          </TableCell>
          <TableCell align="right" onClick={handleShowDetails}>
            {user.phoneNumber}
          </TableCell>
          <TableCell align="right" onClick={handleShowDetails}>
            {user.email}
          </TableCell>
          {role != null && (
            <TableCell align="right">
              <Select
                native
                id="role"
                label="Role"
                type="text"
                color="primary"
                value={role}
                onChange={handleRoleChange}
                variant="outlined"
              >
                <option value={"USER"}>User</option>
                <option value={"MANAGER"}>Manager</option>
                <option value={"ADMIN"}>Admin</option>
              </Select>
            </TableCell>
          )}
          {role == null && (
            <TableCell align="right">
              <Button
                variant="contained"
                color="primary"
                onClick={authorizeUser}
              >
                Authorize?
              </Button>
            </TableCell>
          )}
        </TableRow>
      }
      <UserDetails
        user={user}
        show={showDetails}
        handleClose={handleCloseDetails}
      />
    </React.Fragment>
  );
};

export default UserDetails;
