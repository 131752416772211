import { BayDbModel } from "../../models/bays";

export const BAYSLOADING = "BAYSLOADING";
export const BAYSLOADED = "BAYSLOADED";
export const BAYLOADING = "BAYLOADING";
export const BAYLOADED = "BAYLOADED";
export const BAYUPDATING = "BAYUPDATING";
export const BAYUPDATED = "BAYUPDATED";
export const BOOKING_UPDATED = "BOOKING_UPDATED";
export const BAY_STATUS_UPDATED = "BAY_STATUS_UPDATED";

export interface BayState {
  isLoading: boolean;
  bays: BayDbModel[];
}
