import { useState, useEffect, useCallback, useRef } from 'react';

interface WebSocketHookOptions {
    url: string;
    token: string | null;
    maxReconnectAttempts?: number;
    reconnectInterval?: number;
    keepAliveInterval?: number;
    onMessage?: (data: any) => void;
}

interface WebSocketHookReturn {
    isConnected: boolean;
    isReconnecting: boolean;
    sendMessage: (message: any) => void;
    disconnect: () => void;
}

export const useWebSocket = ({
                                 url,
                                 token,
                                 maxReconnectAttempts = 5,
                                 reconnectInterval = 5000,
                                 keepAliveInterval = 540000, // 9 minutes in milliseconds (just under API Gateway's 10 minute limit)
                                 onMessage,
                             }: WebSocketHookOptions): WebSocketHookReturn => {
    const [isConnected, setIsConnected] = useState(false);
    const [isReconnecting, setIsReconnecting] = useState(false);
    const ws = useRef<WebSocket | null>(null);
    const reconnectAttempts = useRef(0);
    const reconnectTimeoutId = useRef<NodeJS.Timeout>();

    const keepAliveIntervalId = useRef<NodeJS.Timeout>();

    // Store the callback in a ref to avoid recreating the WebSocket on every render
    const onMessageCallback = useRef(onMessage);

    useEffect(() => {
        onMessageCallback.current = onMessage;
    }, [onMessage]);

    const sendPing = useCallback(() => {
        if (ws.current?.readyState === WebSocket.OPEN) {
            sendMessage({
                type: 'ping'
            });
        }
    }, []);

    const connect = useCallback(() => {
        if (!token) return; // Don't connect if we don't have a token

        // Clear any existing connection and intervals
        if (ws.current) {
            ws.current.close();
        }
        if (keepAliveIntervalId.current) {
            clearInterval(keepAliveIntervalId.current);
        }

        const fullUrl = `${url}?Auth=${token}`;
        console.log(fullUrl);
        const socket = new WebSocket(fullUrl);

        socket.onopen = () => {
            console.log(new Date().toISOString() + ' - WebSocket connected');
            setIsConnected(true);
            setIsReconnecting(false);
            reconnectAttempts.current = 0;

            // Start keepalive interval
            keepAliveIntervalId.current = setInterval(sendPing, keepAliveInterval);
        };

        socket.onclose = (event) => {
            console.log(new Date().toISOString() + ' - WebSocket disconnected', event);
            setIsConnected(false);

            // Clear keepalive interval
            if (keepAliveIntervalId.current) {
                clearInterval(keepAliveIntervalId.current);
            }

            if (reconnectAttempts.current < maxReconnectAttempts) {
                setIsReconnecting(true);
                reconnectAttempts.current += 1;
                reconnectTimeoutId.current = setTimeout(() => {
                    console.log(new Date().toISOString() + ` - Reconnecting attempt ${reconnectAttempts.current}...`);
                    connect();
                }, reconnectInterval);
            } else {
                setIsReconnecting(false);
                console.log('Max reconnection attempts reached');
            }
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        socket.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                onMessageCallback.current?.(data);
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
            }
        };

        ws.current = socket;
    }, [token, url, maxReconnectAttempts, reconnectInterval, keepAliveInterval, sendPing]);

    // Clean up on unmount
    useEffect(() => {
        return () => {
            if (reconnectTimeoutId.current) {
                clearTimeout(reconnectTimeoutId.current);
            }
            if (keepAliveIntervalId.current) {
                clearInterval(keepAliveIntervalId.current);
            }
            if (ws.current) {
                ws.current.close();
            }
        };
    }, []);

    // Connect when the component mounts and we have a token
    useEffect(() => {
        if (token) {
            connect();
        }

        // Cleanup on unmount
        return () => {
            if (reconnectTimeoutId.current) {
                clearTimeout(reconnectTimeoutId.current);
            }
            if (ws.current) {
                ws.current.close();
            }
        };
    }, [connect, token]);

    const sendMessage = useCallback((message: any) => {
        if (ws.current?.readyState === WebSocket.OPEN) {
            ws.current.send(JSON.stringify(message));
        } else {
            console.warn('WebSocket is not connected');
        }
    }, []);

    const disconnect = useCallback(() => {
        if (reconnectTimeoutId.current) {
            clearTimeout(reconnectTimeoutId.current);
        }
        if (ws.current) {
            ws.current.close();
        }
        setIsConnected(false);
        setIsReconnecting(false);
        reconnectAttempts.current = maxReconnectAttempts; // Prevent auto-reconnect
    }, [maxReconnectAttempts]);

    return {
        isConnected,
        isReconnecting,
        sendMessage,
        disconnect,
    };
};