import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Bays from "./pages/Bays";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Login from "./pages/Login";
import { LayoutMenu } from "./components/layout/LayoutMenu";
import { Facility } from "./models/facility";
import axios from "axios";
import ProtectedRoute, {
  ProtectedRouteProps,
} from "./components/layout/PrivateRoute";
import { Settings } from "./pages/Settings";
import { RootState } from "./redux/store";
import { useSelector, useDispatch } from "react-redux";
import { AuthState, FacilityAccess } from "./redux/actions/auth.actions.types";
import { selectFacility } from "./redux/actions/facility.actions";
import jwt from "jwt-decode";
import {getBays, updateBay, bookingUpdated, bayStatusUpdated} from "./redux/actions/bays.actions";
import Users from "./pages/Users";
import { refresh, logout } from "./redux/actions/auth.actions";
import {useWebSocket} from "./components/websocket/websocket";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    logo: {
      maxWidth: 160,
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    title: {
      flexGrow: 1,
      textAlign: "center",
    },
  })
);

const App: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const UNAUTHORIZED = 401;

  const facility = useSelector<RootState, Facility>(
    (selector) => selector.facility.selectedFacility
  );

  const defaultProtectedRouteProps: ProtectedRouteProps = {
    authenticationPath: "/login",
    isAllowed: true,
    restrictedPath: "",
  };

  axios.interceptors.request.use(
    function (config) {
      // console.log(token);
        const token = localStorage.getItem("awflitefmtoken") || "";
      if (token != null && token !== "") {
        // console.log(token);
        var isExpired = false;
        var decodedToken: any = jwt(token);
        var dateNow = new Date();

        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(decodedToken.exp);

        if (d < dateNow) isExpired = true;

        // console.log(isExpired);
        if (isExpired) {
          //force logout
          console.log('FORCE LOGOUT');
          dispatch(logout());
        } else {
            config.headers.Authorization = `Bearer ${token}`;
        }
      }

      return config;
    },
    function (err) {
      return Promise.reject(err);
    }
  );

    axios.interceptors.response.use(
        response => response,
        error => {
            const {status} = error.response;
            if (status === UNAUTHORIZED) {
                console.log('UNAUTHORIZED LOGOUT');
                dispatch(logout());
            }
            return Promise.reject(error);
        }
    );


    const selectedFacility = useSelector<RootState, Facility>(
        (selector) => selector.facility.selectedFacility
    );

    // Get this token from your login process
    const [authToken, setAuthToken] = useState<string | null>(null);

    const { isConnected, isReconnecting, sendMessage } = useWebSocket({
        // url: process.env.REACT_APP_WSS_URL!,
        url: `wss://uzt6kwl7ch.execute-api.eu-west-1.amazonaws.com/dev`,
        token: authToken,
        onMessage: (data) => {
            console.log(data);
            // Handle incoming messages here
            if (data.event === 'booking-updated') {
                dispatch(bookingUpdated(data.payload, facility));
            } else if (data.event === 'bay-status-updated') {
                dispatch(bayStatusUpdated(data.payload, facility));
            }
        },

    });

    useEffect(() => {
        if (isConnected && selectedFacility !== undefined && selectedFacility?.rangeId) {
            console.log(new Date().toISOString() + ' - Register websocket for facility', selectedFacility.rangeId);
            sendMessage({
                action: 'register',
                rangeId: selectedFacility.rangeId
            });
        }
    }, [isConnected, selectedFacility, sendMessage]);

    // const [hubConnection, setHubConnection] = useState<HubConnection>();
  // Builds the SignalR connection, mapping it to /chat
  // var url = process.env.REACT_APP_SIGNALR_URL;
  // const hubConnection = new HubConnectionBuilder()
  //   .withUrl(url!, {
  //     skipNegotiation: true,
  //     transport: HttpTransportType.WebSockets,
  //   })
  //   .configureLogging(LogLevel.Error)
  //   .withAutomaticReconnect()
  //   .build();
  //
  // // Starts the SignalR connection
  // if (hubConnection.state !== HubConnectionState.Connected) {
  //   hubConnection.start().then((a) => {
  //     console.log("Connected to signalR");
  //   });
  // }
  //
  // hubConnection.on("sendbayupdate", (message, data) => {
  //     dispatch(updateBay(data, facility));
  // });
  //
  // hubConnection.onreconnecting((error) => {
  //   if (hubConnection.state == HubConnectionState.Reconnecting)
  //     console.log("reconnecting signalR");
  // });

  useEffect(() => {
    //on refresh grab the data from localstorage
    const selectedFacility = localStorage.getItem("selectedfacility");
    // console.log(`selectedFacility: ${selectedFacility}`);
    const localFacilities = localStorage.getItem("availablefacilities");
    // console.log(`localFacilities: ${localFacilities}`);
    // console.log(`token: ${token}`);
    const token = localStorage.getItem("awflitefmtoken");
    // if (!!selectedFacility) {
    if (!!token) {
      // console.log(selectedFacility);
      const decodedToken: any = jwt(token);

        // console.log(token);
        var isExpired = false;
        var dateNow = new Date();
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(decodedToken.exp);

        if (d < dateNow) isExpired = true;

        // console.log(isExpired);
        if (isExpired) {
            //force logout
            console.log('FORCE LOGOUT');
            dispatch(logout());
        } else {
            setAuthToken(token);

            // console.log(decodedToken);
            var facilities: FacilityAccess[];
            if (localFacilities !== null) {
                facilities = JSON.parse(localFacilities);
                dispatch(refresh(facilities));
            }
            if (!!selectedFacility) {
                dispatch(
                    selectFacility(selectedFacility, decodedToken[selectedFacility])
                );
                dispatch(getBays(selectedFacility));
            }
        }
    } else {
      // console.log("token missing in localstorage");
    }
    // } else {
    //   console.log("selectedFacility empty in localstorate");
    // }
  }, []);

  return (
    <div className={classes.root} style={{ background: "#f5f5f5" }}>
      <CssBaseline />
      <BrowserRouter>
        <LayoutMenu facility={facility} />
        <main className={classes.content}>
          {/* <div className={classes.toolbar} /> */}
          <Switch>
            <Route
              exact={true}
              path="/login"
              render={(props) => (
                <React.Fragment>
                  <Login />
                </React.Fragment>
              )}
            />
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              exact={true}
              path="/"
              component={Bays}
            />
            {/*<ProtectedRoute*/}
            {/*  {...defaultProtectedRouteProps}*/}
            {/*  path="/reports"*/}
            {/*  component={Reports}*/}
            {/*/>*/}
            {/*<ProtectedRoute*/}
            {/*  {...defaultProtectedRouteProps}*/}
            {/*  path="/players"*/}
            {/*  render={(props) => <Players />}*/}
            {/*/>*/}
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              path="/settings"
              render={(props) => <Settings facility={facility} />}
            />
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              path="/users"
              component={Users}
            />
          </Switch>
        </main>
      </BrowserRouter>
    </div>
  );
};

export default App;
